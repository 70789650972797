
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OptimizingDialog',
  components: { DialogTemplate },
  emits: ['confirm', 'close'],
  setup (props, ctx) {
    return {
      completed: () => {
        ctx.emit('close')
      },
      optimizing: () => {
        ctx.emit('confirm')
      },
    }
  },
})
