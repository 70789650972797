
import { formatMoney } from '@/common/format'
import Button from '@/components/Button.vue'
import ChoiceButton from '@/components/ChoiceButton.vue'
import { DEPOSIT_ERROR, useDeposit, useSetOptimizing } from '@/modules/in/in'
import { DepositChannel, readDepositChoices } from '@/modules/in/in.api'
import TheChannels from '@/modules/out/components/TheChannels.vue'
import HoldOn from '@/provider/HoldOn.vue'
import ExchangeRate from '@/views/ExchangeRate.vue'
import Matrix from 'common/Matrix.vue'
import Pic from '@/components/Pic.vue'
import { mul } from 'essential/tools/math'
import { defineComponent, shallowRef, watchEffect } from 'vue'
import { format, keymap } from '@/config'
import { useRoute } from 'vue-router'

import { liveDepositPointList } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import * as R from 'ramda'
import OptimizingBtn from './components/OptimizingBtn.vue'
import OptimizingDialog from './components/OptimizingDialog.vue'
import { openDialog } from '@/components/popup/popup'
import router from '@/router'
import { localGet, localRemove, localSet } from 'essential/store/localStore'

export default defineComponent({
  name: 'ChannelFor91',
  components: {
    TheChannels,
    ChoiceButton,
    ExchangeRate,
    HoldOn,
    Button,
    Matrix,
    Pic,
    OptimizingBtn,
  },
  props: {
    channels: {
      type: Array,
      required: true,
    },
    customRequest: Function,
  },
  setup (props) {
    const amount = shallowRef('0')
    const [commit, progress] = useDeposit()
    const channel = shallowRef<string>('')
    const options = shallowRef()
    const selectIndex = shallowRef(0)
    const disabled = shallowRef(false)
    const rate = shallowRef({ rate: 1 })
    const { showOptimizeBtn, show, hide } = useSetOptimizing()

    const route = useRoute()

    const pointList = shallowRef<{amount: string, points: string}[]>([])

    const duringEvent = shallowRef(false)

    const checkPayResult = () => {
      if (localGet(keymap.recharge.optimize)) {
        localRemove(keymap.recharge.optimize)
        openDialog(OptimizingDialog, {
          onConfirm: () => {
            show()
            router.back()
          },
        })
      }
    }

    if (route.query.during) {
      duringEvent.value = true
      Promise.all([readDepositChoices(), liveDepositPointList({
        roomId: route.query.roomid,
      })]).then(res => {
        for (const key in res[1].list) {
          pointList.value.push({
            amount: key,
            points: res[1].list[key].toString(),
          })
        }

        options.value = res[0].list
      })
    } else {
      readDepositChoices().then(res => {
        options.value = res.list
      })
    }

    // Promise.all([readDepositChoices(), liveDepositPointList({
    //   roomid: route.query.roomid,
    // })]).then(res => {
    //   for (const key in res[1].list) {
    //     pointList.value.push({
    //       amount: key,
    //       points: res[1].list[key].toString(),
    //     })
    //   }

    //   options.value = res[0].list
    // })

    const calcPoint = (amount: string | number) => {
      const item = R.find(R.propEq('amount', amount))(pointList.value) as {amount: string, points: string}
      if (item) {
        return item.points
      }
    }

    const disableInit = () => {
      const camount = amount.value === '0' ? Number((options.value ?? ['0'])[0]) : Number(
        amount.value)
      const cmax = (props.channels as DepositChannel[])?.[selectIndex.value].maxAmount
      const cmin = (props.channels as DepositChannel[])?.[selectIndex.value].minAmount
      const formattedAmount = formatByRate(camount)
      disabled.value = formattedAmount > formatMoney(cmax).origin || formattedAmount < formatMoney(cmin).origin
    }

    watchEffect(() => {
      // 默认选中可选金额
      for (let i = 0; i < options.value?.length; i++) {
        if (+options.value[i] >= +(props.channels as DepositChannel[])?.[selectIndex.value].minAmount) {
          amount.value = options.value[i]
          break
        }
      }
      // amount.value = (options.value ?? ['0'])[0]
    })

    watchEffect(() => {
      channel.value = (props.channels as DepositChannel[])?.[0]?.code
    })

    const changeDepositAmount = (item: string) => {
      amount.value = item
      disableInit()
    }

    const request = (amount: string) => {
      if (disabled.value) {
        return
      }
      const params: Record<string, unknown> = {
        amount,
      }

      if (route.query.roomid) {
        params.roomid = route.query.roomid
      }

      if (channel.value) {
        params.payChannel = channel.value
      }

      if (typeof props.customRequest === 'function') {
        return props.customRequest(params, commit)
      }

      // 选择印度ipay支付通道是弹出修复支付通道弹窗
      if (channel.value === 'default_pay') {
        localSet(keymap.recharge.optimize, '1')
      }

      return commit(params)
        .then(checkPayResult)
        .catch((e: DEPOSIT_ERROR) => {
          if (e === DEPOSIT_ERROR.FAILED) {
            checkPayResult()
          }
        })
    }

    const channelChange = (index: number) => {
      selectIndex.value = index
      disableInit()
    }

    const optimizeFinish = () => {
      hide()
    }

    function onRateChange (rRate: { rate: number }) {
      rate.value = rRate
      disableInit()
    }

    function formatByRate (v: string | number) {
      return formatMoney(mul(v, rate.value.rate)).origin
    }

    return {
      channel,
      options,
      amount,
      progress,
      selectIndex,
      disabled,
      commit: request,
      changeDepositAmount,
      channelChange,
      format,
      pointList,
      calcPoint,
      duringEvent,
      optimizeFinish,
      onRateChange,
      formatMoney,
      formatByRate,
      showOptimizeBtn,
      show,
    }
  },
})
