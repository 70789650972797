
import Button from '@/components/Button.vue'
import { defineComponent, onUnmounted, shallowRef, watch } from 'vue'

export default defineComponent({
  name: 'OptimizingBtn',
  components: {
    Button,
  },
  props: {
    began: Boolean,
  },
  emits: ['finish'],
  setup (props, ctx) {
    const rate = shallowRef('')
    const loading = shallowRef(false)
    let loadingTimer = 0
    let finishTimer = 0

    const finishOptimize = () => {
      loading.value = false
      finishTimer = window.setTimeout(() => {
        ctx.emit('finish')
      }, 1000)
    }

    const startOptimize = () => {
      let persent = 0
      loading.value = true
      loadingTimer = window.setInterval(() => {
        persent = persent + 1
        if (persent <= 100) {
          rate.value = persent + '%'
        } else {
          clearInterval(loadingTimer)
          finishOptimize()
        }
      }, 30)
    }

    onUnmounted(() => {
      clearInterval(loadingTimer)
      clearTimeout(finishTimer)
    })

    watch(() => props.began, () => {
      if (props.began) {
        startOptimize()
      }
    }, {
      immediate: true,
    })
    return {
      rate,
      loading,
    }
  },
})
