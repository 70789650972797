<template>
  <DialogTemplate :noControls="true">
    <template #title>
      <t class="f-bold" path='withdrawal_102'>How was your last payment?</t>
    </template>
    <div class="t-center" style="width: 100%;">
      <button class="pay-success btn block" @click="completed">
        <t path='withdrawal_103'>payment completed</t>
      </button>
      <button class="pay-error btn block" @click="optimizing">
        <t path='withdrawal_104'>Encountered some problems,not completed</t>
      </button>
    </div>
    <template #confirmText>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OptimizingDialog',
  components: { DialogTemplate },
  emits: ['confirm', 'close'],
  setup (props, ctx) {
    return {
      completed: () => {
        ctx.emit('close')
      },
      optimizing: () => {
        ctx.emit('confirm')
      },
    }
  },
})
</script>

<style scoped lang='scss'>
.pay-success{
  width: 100%;
  height: 48px;
  background: #00B167;
  border-radius: 6px;
  font-size: 17px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 32px;
}
.pay-error{
  width: 100%;
  height: 48px;
  background: #326BFE;
  border-radius: 6px;
  font-size: 17px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 24px;
  margin-bottom: 40px;
}
</style>
